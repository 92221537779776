

import ZmCheckboxGroup from '../Checkbox/src/CheckboxGroup.vue'
import { App } from 'vue'


ZmCheckboxGroup.install = (app: App) => {
    app.component(ZmCheckboxGroup.name, ZmCheckboxGroup)
}

export default ZmCheckboxGroup