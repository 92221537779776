
import { computed, ref, inject } from 'vue'

const useCheckbox = (props: any, ctx: any) => {
    // const selfModel = ref(false)
    const isGroup = (() => {
        const componentName = inject('componentName', null);
        if (componentName === 'zmCheckboxGroup') {
            return true
        }
        return false
    })()
    let checkboxModel: any = null;
    if (isGroup) {
        checkboxModel = inject('CheckboxModel')
    }

    const CheckboxSize = computed(() => {
        return props.size
    })
    const isDisabled = computed(() => {
        return props.disabled
    })
    const model = computed({
        get() {
            if (isGroup) {
                if (checkboxModel !== null && checkboxModel !== undefined) {
                    return checkboxModel
                }
            }
            // return props.value !== undefined ? props.value : selfModel.value;
            return props.modelValue
        },
        set(val: boolean) {
            if (isGroup) {
                const index = checkboxModel.findIndex((item: string) => {
                    return item === props.label
                })
                if (index > -1) {
                    checkboxModel.splice(index, 1)
                } else {
                    checkboxModel.push(props.label)
                }

            } else {
                // ctx.emit('update:value', val)
                // selfModel.value = val
                ctx.emit('update:modelValue', val)
            }

        }
    })
    const isChecked = computed(() => {
        if ({}.toString.call(model.value) === '[object Boolean]') {
            // 单独使用checkbox
            return model.value
        } else if (Array.isArray(model.value)) {
            // 在checkboxGroup中使用
            return model.value.indexOf(props.label) > -1
        } else if (model.value !== null && model.value !== undefined) {
            return model.value === props.trueLabel
        }
    })


    const handleChange = (event: any) => {
        ctx.emit('on-change', model.value)
    }
    return {
        CheckboxSize,
        model,
        isDisabled,
        isChecked,
        handleChange
    }
}

export default useCheckbox