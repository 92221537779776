<template>
  <label
    class="zm-checkbox"
    :class="[
      border && CheckboxSize ? 'zm-checkbox__' + CheckboxSize : '',
      {
        'is-checked': isChecked,
        'is-disabled': isDisabled,
        'is-border': border,
      },
    ]"
  >
    <span
      class="zm-checkbox__input"
      :class="[
        {
          'is-checked': isChecked,
          'is-disabled': isDisabled,
          'is-border': border,
          'is-focus': focus,
          'is-circle': circle,
        },
       
      ]"
    >
      <span class="zm-checkbox__inner" :style="inputStyle"></span>
      <input
        type="checkbox"
        class="zm-checkbox__original"
        v-model="model"
        name
        :disabled="disabled"
        id
        @change="handleChange($event, model)"
      />
    </span>
    <span class="zm-checkbox__label">
      <slot>{{ label }}</slot>
    </span>
  </label>
</template>

<script lang='ts'>
/**
 * 1. 可以调整边框以及大小型号
 * 2. 可以禁用、可以选中以及选中的样式、拥有边框的边框样式
 * 3. 选中之后的回事件
 * 4. 自定义文案显示 默认为label
 */
import { ref, defineComponent } from "vue";
import useCheckbox from "./checkbox";
export default defineComponent({
  name: "checkbox",
  props: {
    border: {
      type: Boolean,
      default: false
    },
    circle: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "medium"
    },
    label: {
      type: [String, Boolean, Number],
      default: ""
    },
    trueLabel: {
      type: [String, Number],
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    inputStyle: {}
  },
  setup(props: any, ctx: any) {
    const focus = ref(false);
    const {
      CheckboxSize,
      model,
      isDisabled,
      isChecked,
      handleChange
    } = useCheckbox(props, ctx);
    return {
      focus,
      CheckboxSize,
      model,
      isDisabled,
      isChecked,
      handleChange
    };
  }
});
</script>

<style scoped>
@import url("./checkbox.css");
</style>