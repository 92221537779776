

import ZmCheckbox from './src/Checkbox.vue'
import { App } from 'vue'


ZmCheckbox.install = (app: App) => {
    app.component(ZmCheckbox.name, ZmCheckbox)
}

export default ZmCheckbox