
import { computed, defineComponent, reactive, ref, watch, inject } from "vue";
import UiCheckboxGroup from "@/components/UI/CheckboxGroup";
import UiCheckbox from "@/components/UI/Checkbox";

import axios from "@/api/axios";
import api from "@/api/axios";
import Toast from "@/components/UI/Toast";
import useShopCar from "@/hooks/useShopCar";
import Loading from "@/components/UI/Loading/src/Loading.vue";
import qs from "qs";
import Modal from "@/components/UI/Modal";
import router from "@/router";
import Empty from "@/components/common/Empty.vue";
import { getCartInfo } from "@/api/useChangeShopCar";
export default defineComponent({
  name: "ShopCar",
  components: {
    UiCheckbox,
    UiCheckboxGroup,
    Loading,
    Empty,
  },
  setup() {
    const biaowuPriceOpen = ref()
    const postageFee = ref()
    const delivery = ref(1)
    const list = ref<[] | null>(null);

    const checked = ref(true);
    const checked1 = reactive<any>([]);
    const loading = ref(true);
    const totalPrice = ref(0)
    const userInfo = inject("userInfo") as any;
    const changeBox = (val: any) => {
      let allPrice = 0
      if (val && list.value) {
        checked1.length = 0;
        list.value.forEach((item: any) => {
          checked1.push(item.pidAndLotId);
          allPrice += item.qty * item.price
        });
        totalPrice.value = allPrice
      } else {
        checked1.length = 0;
        totalPrice.value = 0
      }
    };
    const map = new Map();
    const count = ref(0);
    const changeBoxGroup = (val: any) => {
      let singPrice = 0
      if (val && list.value) {
        list.value.forEach((item: any) => {
          val.forEach((ele: any) => {
            if(item.pidAndLotId == ele) {
              singPrice += item.qty * item.price
            }
          });
        });
        totalPrice.value = singPrice
      }

      if (
        list.value &&
        list.value.length &&
        val.length == list.value.length
      ) {
        checked.value = true;
      } else {
        checked.value = false;
      }

      let newCount = 0;
      val.forEach((item: any) => {
        const value = Number(map.get(item));
        if (value) {
          newCount += value;
        }
      });
      count.value = newCount;
    };
    const { addShopCar, addStorge, subStorge } = useShopCar();
    const guid = localStorage.getItem("guid");
    function getCount(newValue: any[]) {
      let newCount = 0;
      newValue.forEach((item: any) => {
        const value = Number(map.get(item));
        if (value) {
          newCount += value;
        }
      });
      if (
        list.value &&
        list.value.length &&
        newValue.length == list.value.length
      ) {
        checked.value = true;
      } else {
        checked.value = false;
      }
      count.value = newCount;
      let singPrice = 0
      if (newValue && list.value) {
        list.value.forEach((item: any) => {
          singPrice += item.qty * item.price
        });
        totalPrice.value = singPrice
      }
    }
    function getData(stopRefresh?: boolean) {
      checked1.length = 0;
      axios
        .get("/M/ShoppingCart", {
          params: {
            cartGUID: guid,
          },
        })
        .then((res) => {
          if (res.data.success) {
            const data = res.data.obj.list;
            biaowuPriceOpen.value = res.data.obj.biaowuPriceOpen;
            postageFee.value = res.data.obj.postageFee;
            let startPrice = 0
            data.length>0 && data.forEach((item: any) => {
              startPrice += item.qty * item.price
            });
            totalPrice.value = startPrice
            list.value = data;
            if (!stopRefresh) {
              data.forEach((item: any) => {
                map.set(item.pidAndLotId, item.qty);
                checked1.push(item.pidAndLotId);
              });
            } else {
              checked1.length = 0;
              data.forEach((item: any) => {
                map.set(item.pidAndLotId, item.qty);
                checked1.push(item.pidAndLotId);
              });
            }
            getCount(checked1);
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
          loading.value = false;
        })
        .catch((err) => {
          Toast({
            type: "error",
            title: err.message,
          });
          loading.value = false;
        });
    }
    getData();

    watch(
      () => {
        return checked1;
      },
      (newValue) => {
        getCount(newValue);
      }
    );

    function onAdd(id: string, index: number) {
      (list.value as any)[index].qty += 1;
      // count.value += 1;
      map.set(id, (list.value as any)[index].qty);
      getCount(checked1);
      addStorge(id, (list.value as any)[index].qty);
    }
    function onSub(id: string, index: number) {
      if ((list.value as any)[index].qty <= 1) {
        Toast({
          type: "warning",
          title: "More than 1 item.",
        });
        return false;
      }
      (list.value as any)[index].qty -= 1;
      // count.value -= 1;
      map.set(id, (list.value as any)[index].qty);
      getCount(checked1);
      addStorge(id, (list.value as any)[index].qty);
    }
    const loadOption = {
      text: "loading...",
      color: "#df0024",
      textColor: "#df0024",
    };

    function onDelete() {
      if (!checked1.length) {
        Toast({
          type: "warning",
          title: " Select item to delete.",
        });

        return false;
      }
      Modal({
        title: "System prompt",
        content: "Are you sure you want to delete?",
        onConfirm: () => {
          const data = qs.stringify({
            ids: checked1.join(","),
          });
          axios
            .post("/M/Server/DeleteCartItem", data, {
              params: {
                cartGUID: guid,
              },
            })
            .then((res) => {
              if (res.data.success) {
                totalPrice.value = 0
                getData(true);
                getCartInfo()
              } else {
                Toast({
                  type: "error",
                  title: res.data.msg,
                });
              }
              loading.value = false;
            })
            .catch((err) => {
              Toast({
                type: "error",
                title: err.message,
              });
              loading.value = false;
            });
        },
      });
    }

    function onSubmit() {
      if (!count.value) {
        Toast({
          type: "warning",
          // title: "请选择要结算的商品！",
          title: " Select items to checkout.",
        });
        return false;
      }
      axios
        .post(
          "/M/Server/DoCheckoutNew",
          qs.stringify({
            idstr: checked1.join(","),
          }),
          {
            params: {
              validate: true,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            Modal({
              title: "System prompt",
              content: "Dear user, your order has been submitted successfully!",
              closeText: "close",
              onClose: () => {
                getCartInfo()
                router.push({
                  name: "Order",
                  query: {
                    type: 0,
                  },
                });
              },
            });
          } else {
            if(res.data.msg=='NOAddress'){
              Modal({
                title: "System prompt",
                content: "No delivery address, please add the delivery address.",
                closeText: "close",
                confirmText:"to add",
                onConfirm: () => {
                  getCartInfo()
                  router.push({
                    name: "addAddress",
                  });
                },
              });
            }else{
              Toast({
                type: "error",
                title: res.data.msg,
                duration:3000
              });
            }
          }
        })
        .catch((err) => {
          Toast({
            type: "error",
            title: err.message,
          });
        });
    }
    function changeEdit(id: string, item: any, index: any) {
      if (item<=0) {
        Toast({
          type: "error",
          title: 'More than 1 item.',
          onClose: () => {
            location.reload();
          },
        });
        return false
      }
      addStorge(id, item);
    }
    return {
      list,
      checked1,
      checked,
      changeBox,
      changeBoxGroup,
      onAdd,
      onSub,
      count,
      loadOption,
      loading,
      onDelete,
      onSubmit,
      totalPrice,
      userInfo,
      delivery,
      biaowuPriceOpen,
      postageFee,
      changeEdit,

    };
  },
});
