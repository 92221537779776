
import { reactive, ref, provide, watchEffect , defineComponent } from "vue";
import useCheckbox from "./checkbox";

export default defineComponent({
  name: "checkboxGroup",
  componentName: "zmCheckboxGroup",
  props: {
    border: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "medium",
    },
    label: {
      type: [String, Boolean, Number],
      default: "",
    },
    value: {
      type: Array,
      default: ()=>{
        return []
      },
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: "",
    },
  },
  setup(props: any, ctx: any) {
    const componentName = "zmCheckboxGroup";
    const { CheckboxSize, isDisabled } = useCheckbox(props, ctx);
    const value = reactive(props.value);
    watchEffect(() => {
      // 在这里添加对value内部元素的依赖，不然这个函数不会重新触发？？？
      const cancelEffect = [...value];
      ctx.emit("update:value", value);
      ctx.emit("on-change", value);
    });
    provide("componentName", componentName);
    provide("CheckboxModel", value);
    return {
      CheckboxSize,
      isDisabled,
    };
  },
})
